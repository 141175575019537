import React, { useState, useEffect } from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { navigate } from "gatsby"

const WhatsNew = () => {
  useEffect(() => {
      if (window.location.href.indexOf('#whatsnew') !== -1) {
        console.log('use effect in what\'s new');
        navigate('/#whatsnew');
      }
  }, []);
  return (
      <section id='whatsnew'>
        <div className='row'>
          <div className='twelve columns main-col'>
            <div className='row item'>
              <div className='twelve columns'>
                <div className="main-eye-catch-0" style={{ "display": "none"}}>
                  <iframe width="95%" height="350px" src="https://www.youtube.com/embed/BbxtNbLVVc8" title="2022.9.28&29 JUNKO MORIYA ORCHESTRA 2Days with ASAKO TOKI リハーサル映像" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
              <div>
            <h2>What's New</h2>
                <h3>時々動画をあげています        <a href="https://www.youtube.com/channel/UCxhSx5bX4N9xxcaMJSkWpPg">
          <i className="fa fa-youtube"></i>
        </a>
</h3>
                <p><a href="https://www.youtube.com/channel/UCxhSx5bX4N9xxcaMJSkWpPg">Junko Moriya Channel</a>をご覧ください。<br/>チャンネル登録もよろしくお願いいたします。</p>
</div>
              </div>
              <hr class="hr-top"/>
            </div>


            <div className='row item'>
              <div className='twelve columns' style={{"margin-left":"2rem"}}>
                <h3>ライン登録はこちら</h3>
                <p>
                 <a href="https://lin.ee/5gcJt9E"><img style={{height: 33}} src="https://scdn.line-apps.com/n/line_add_friends/btn/ja.png"/></a>
                </p>
              </div>
              <hr/>
            </div>





       <div className='row item'>
              <div className='twelve columns' style={{"margin-left":"2rem", "margin-bottom": "2rem"}}>
　　　　　　　    <StaticImage className="main-eye-catch" style={{"width":"50%", "margin-right":"2rem"}}
　　　　　　        alt="BODY&SOUL 2025.0105"
　　　　　　        src="../images2/250105bodyandsoul.jpg"
　　　　　　      />
                <h3>Junko Moriya Sextet 2025 Opening Special Live! @渋谷公園通りBODY&SOUL</h3>
                <p>2025.01.05(日) 渋谷公園通りBODY&SOUL<br/>
                open14:00--/ 1st 15:00-,2nd 16:30--入替なし <br/>
                MC. 一般4600円、学生税込3000円</p>
                <p>
日本を代表する老舗ジャズクラブ・BODY&SOULの2025年口開けライブです！2025年の年明けを豪華Sextetとご一緒に、お祝いしましょう！<a href="https://www.bodyandsoul.co.jp/event/250105">2025.01.05　BODY&SOUL50周年記念特別ライブ！/Junko Moriya Sextet</a>
                </p>
              </div>
              <hr/>
            </div>



{/*
              <div className='row item'>
              <div className='twelve columns' style={{"margin-left":"2rem"}}>
 　　　　　　　　　　　　<StaticImage className="main-eye-catch-l" style={{"width":"50%", "margin-right":"2rem"}}
　　　　　　       　 alt="赤坂Jazz Dining B-flat　2024.1129"
　　　　　　        　src="../images2/20241129_B-Flat.jpg"
　　　　　　　　　　　　/>
　　　　　　　       <h3>Junko Moriya Orchestra@Jazz Dining B-flat </h3>
                <p>
2024.11.29(金) 赤坂Jazz Dining B-flat <br/>open18:00--/ 1st 19:00-,2nd 20:15--入替なし <br/>
                 一般　4,400円(税込4,840円）,学生2,500円（税込2,750円）</p>
                <p>
『守屋純子オーケストラ』が赤坂B-flatに最登場！多くの社会人・学生ビッグバンド に取り上げられているオリジナルや、新解釈のスタンダードなど・・・絶対にここでしか出会えない音にご期待ください！美味しいお料理やお酒も楽しんでいただけます。<br/>

<a href="https://bflat.yamano-music.co.jp/event?contentId=a5c6j4mlhg">2024.11.29　Junko Moriya Orchestra@Jazz Dining B-flat</a>
                </p>
              </div>
              <hr/>
            </div>

*/}



           <div className='row item'>
              <div className='twelve columns' style={{"margin-left":"2rem", "margin-bottom": "2rem"}}>
　　　　　　　    <StaticImage className="main-eye-catch-l" style={{"width":"50%", "margin-right":"2rem"}}
　　　　　　        alt="守屋純子オーケストラ 2025年定期公演・25周年記念＜Tribute to Benny Golson＞"
　　　　　　        src="../images2/2025sakurahall_omote.jpg"
　　　　　　 　　　  />
                <h3>『2025.2.28(金)守屋純子オーケストラ 2025年定期公演・25周年記念』</h3>
                <p><b>2025.2.28(金) 渋谷文化総合センター大和田・さくらホール　　open:18:15--  /Start: 19:00--</b><br/> 
               チケット代金　一般4800円（税込・全席指定）、学生 3000円（税込・当日引換）　</p>
                <p>
毎年2月に行なってきたこのコンサートも、今年で第25回を迎えます。チケットぴあ等でも扱っておりますが、最も良いお席が選べる、HP販売については、以下をクリックしてご覧ください。<a href="https://www.junkomoriya.com/rp2025i">2025.02.28(金)守屋純子オーケストラ 2025年定期公演</a>
                </p>
              </div>
              <hr/>
            </div>




{/*

            <div className='row item'>
              <div className='twelve columns' style={{"margin-left":"2rem"}}>
              <StaticImage className="main-eye-catch-l" style={{"width":"50%", "margin-right":"2rem"}}
　　　　　　       　 alt="2024.12.6 Gillock講習会"
　　　　　　        　src="../images2/2024.12.06.Gillock.jpg"
　　　　　　　　　　　　/>

                <h3>2024.12.6（金）【セミナー・ピアノ指導法】＜ギロックを通して始めるジャズへの第一歩＞＠島村楽器市川店</h3>
                <p>
興味はあるけれど何からはじめればよいかわからない」から一歩踏み出し、ギロックの『ジャズスタイルピアノ曲集』を使って新たな世界を覗いてみましょう！心動かされる体験を通して興味・関心・探求を深めてみませんか？オンライン受講もあります。<br/>
 <a href="https://www.shimamura.co.jp/shop/colton-piano/article/etc/20241005/6322">https://https://www.shimamura.co.jp/shop/colton-piano/article/etc/20241005/6322</a>
                </p>
              </div>
              <hr/>

            </div>


 */}


{/*

           <div className='row item'>
              <div className='twelve columns' style={{"margin-left":"2rem", "margin-bottom": "2rem"}}>
　　　　　　　    <StaticImage className="main-eye-catch-l" style={{"width":"50%", "margin-right":"2rem"}}
　　　　　　        alt="長津田ハーモナイズポケット"
　　　　　　        src="../images2/harmonizep.jpeg"
　　　　　　 　　　  />
                <h3>『2024.9.22(日)守屋純子trio@長津田ハーモナイズポケット』</h3>
　       <p><b>2024.9/22（日)長津田ハーモナイズポケット　open:14:30-- /Start:15:00(17:00頃終了）</b><br/> 
               MC:3000円</p>
                <p>
長津田駅南口徒歩2分にあるすてきなジャズカフェで贅沢な時間を！初出演です。メンバー：岡崎正典(TS)、佐藤慎一(B)<a href="https://www.instagram.com/harmonizedpocket/">2024.9.22 守屋純子TRIO</a>
                </p>
              </div>
              <hr/>
            </div>

 */}





{/*
            <div className='row item'>
              <div className='twelve columns'>
                <h3>2024.11.29(金)守屋純子オーケストラ@赤坂jazz dining B-flat</h3>
<iframe width="560" height="315" src="https://www.youtube.com/embed/Jvcd05kecho?si=siEmNpxR7XByieDe" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
              </div>
              <hr/>
            </div> 

*/}


          </div>
        </div>
      </section>

  );
}


export default WhatsNew
